import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './LandingPage.module.css'; // Import as a module

function LandingPage() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  // Dynamically import Bootstrap CSS only for this component
  useEffect(() => {
    const loadBootstrap = async () => {
      await import('bootstrap/dist/css/bootstrap.min.css');
    };
    loadBootstrap();
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form with email:', email); // Debugging line
    try {
      const response = await axios.post('https://www.keepme.gr/api/register-email', { email });

      console.log('Submission response:', response); // Check server response
      setSubmitted(true);
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      // Optionally update the UI to inform the user that an error occurred
    }
  };

  return (
    <div className={styles['landing-page-container']}>
      <div className={styles['landing-page-card']}> {/* Use CSS Module classes */}
        <div className={styles['landing-header']}>
          <h1>KeepMe</h1>
          <h2>Your Digital Business Card Solution</h2>
          <p>Effortlessly share your professional identity with just a scan. Transition to the digital era with your custom QR-coded business card today.</p>
        </div>
        {!submitted ? (
          <form onSubmit={handleSubmit} className={styles['email-register-form']}>
            <label htmlFor="email">Create yours for free:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email address"
              className="form-control" // Keep Bootstrap class for styling input
              required
            />
            <button type="submit" className="btn btn-primary mt-3">Register</button> {/* Keep Bootstrap classes for button */}
          </form>
        ) : (
          <div className={styles['thank-you-message']}>
            <h2>Thank You for Registering!</h2>
            <p>We're excited to have you on board. Keep an eye on your inbox for updates on how to make the most out of your new digital business card.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LandingPage;
