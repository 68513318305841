// src/UserProfile.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './UserProfile.css'; // Ensure this CSS file exists and styles your components
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMobileAlt, faEnvelope, faAddressBook, faGlobe, faBook } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';


function UserProfile() {
  const { companyName, username } = useParams();
  const [profile, setProfile] = useState(null);

  const baseImageUrl = 'https://www.keepme.gr'; // URL to prepend to the image path
  const logoContainerRef = useRef(null); // Ref for the logo container

  useEffect(() => {
    axios.get(`https://keepme.gr/api/card/${companyName}/${username}/`)
      .then(response => {
        setProfile(response.data);
        console.log(response.data);
        if (response.data.card_color) {
          document.documentElement.style.setProperty('--card-color', response.data.card_color);
        }
        // Dynamically import CSS based on industry
        importIndustryCss(response.data.industry);
      })
      .catch(error => console.error("There was an error retrieving the profile data:", error));
  }, [companyName, username]);

  useEffect(() => {
    window.addEventListener('load', adjustHeightForMobileUI);
    window.addEventListener('resize', adjustHeightForMobileUI);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('load', adjustHeightForMobileUI);
      window.removeEventListener('resize', adjustHeightForMobileUI);
    };
  }, []);


  const importIndustryCss = (industry) => {
    // Default CSS file if industry-specific styling is not available
    let cssFile = 'default.css';
    switch(industry) {
      case 'Dentist':
        cssFile = 'Dentist.css';
        break;
      case 'Tech':
        cssFile = 'Tech.css';
        break;
      case 'Education':
        cssFile = 'Education.css';
        break;
      // Add more cases as needed
    }
    import(`./styles/${cssFile}`).then(() => {
      console.log(`Loaded ${cssFile} successfully`);
    }).catch(err => {
      console.error(`Failed to load ${cssFile}:`, err);
    });
  };


  const viewCatalog = () => {
    if (profile && profile.product_catalog) {
      // Prepend the base URL to the relative path
      const fullCatalogUrl = `https://keepme.gr/${profile.product_catalog}`;
      window.open(fullCatalogUrl, '_blank');
    }
  };

  function adjustHeightForMobileUI() {
    const viewportHeight = window.innerHeight; // Get the actual visible viewport height
    const profileCard = document.querySelector('.profile-card'); // Select your main container

    if (profileCard) {
      profileCard.style.height = `${viewportHeight * 0.9}px`; // Adjust to 90% of the viewport height, or as needed
    }
  }

  window.addEventListener('load', adjustHeightForMobileUI);
  window.addEventListener('resize', adjustHeightForMobileUI);

  const openWebsite = () => {
    if (profile && profile.company_website) {
      window.open(profile.company_website, '_blank');
    }
  };


  const downloadVCard = () => {
    const vCardData = [
      'BEGIN:VCARD',
      'VERSION:3.0',
      `N:${profile.last_name};${profile.first_name}`,
      `FN:${profile.first_name} ${profile.last_name}`,
      `TEL;TYPE=WORK,VOICE:${profile.telephone}`,
      `TEL;TYPE=MOBILE,VOICE:${profile.mobile}`,
      `EMAIL;TYPE=WORK,INTERNET:${profile.email}`,
      `ADR;TYPE=WORK:${profile.address};${profile.city};;${profile.postcode};${profile.country}`,
      `ORG:${profile.company}`,
      `TITLE:${profile.position}`,
      'END:VCARD'
    ].join('\n');

    const blob = new Blob([vCardData], { type: 'text/vcard;charset=utf-8' });
    saveAs(blob, `${profile.first_name}_${profile.last_name}.vcf`);
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  const logoUrl = profile.logo ? `${baseImageUrl}${profile.logo}` : '';
  // const cardStyle = {
  //   backgroundColor: profile.card_color || '#FFFFFF' // default color if none provided
  // };

  const profileActions = () => {
    if (profile.industry === "Dentist") {
      return (
        <>
          <button className="btn" onClick={downloadVCard}>
            <FontAwesomeIcon icon={faAddressBook} className="icon" />
            <span className="btn-text">Add to Contacts</span>
          </button>
          <button className="btn" onClick={() => window.open(profile.instagram_link, '_blank')}>
            <FontAwesomeIcon icon={faInstagram} className="icon" />
            <span className="btn-text">Instagram</span>
          </button>
          <button className="btn" onClick={viewCatalog}>
            <FontAwesomeIcon icon={faBook} className="icon" />
            <span className="btn-text">Cases</span>
          </button>
        </>
      );
    } else {
      // Your default set of actions for other industries
      return (
        <>
          <button className="btn" onClick={downloadVCard}>
            <FontAwesomeIcon icon={faAddressBook} className="icon" />
            <span className="btn-text">Add to Contacts</span>
          </button>
          <button className="btn" onClick={openWebsite}>
            <FontAwesomeIcon icon={faGlobe} className="icon" />
            <span className="btn-text">Website</span>
          </button>
          <button className="btn" onClick={viewCatalog}>
            <FontAwesomeIcon icon={faBook} className="icon" />
            <span className="btn-text">Product Catalog</span>
          </button>
        </>
      );
    }
  };
  

  return (
    
    <div className="profile-wrapper"> {/* Wrapper div to contain both the profile card and the footer */}
    <Helmet>
      <title>{profile ? `${profile.first_name} ${profile.last_name}` : 'KeepMe'}</title>
    </Helmet>
    <div className="profile-card">
      <div className="profile-info">
        {profile.logo && (
          <div className="company-logo" ref={logoContainerRef}>
            <img src={`${baseImageUrl}${profile.logo}`} alt="Company Logo" className="logo" onLoad={(e) => {
              if (e.target.naturalHeight < 400) {
                // Adjust the container's margin if logo height is less than 400px
                logoContainerRef.current.style.marginTop = '7dvh'; // Adjust this value as needed
                logoContainerRef.current.style.marginBottom = '5dvh'; // Adjust this value as needed
              } else {
                // Reset to default or adjust to other preferred values
                logoContainerRef.current.style.marginTop = '4dvh'; // Original value
                logoContainerRef.current.style.marginBottom = '0'; // Assuming you want to reset the bottom margin
              }
            }} />
          </div>
        )}
        <div className="first_name">{profile.first_name} </div>
        <div className="last_name">{profile.last_name}</div>
        <div className="position">{profile.position}</div>
        <div className="company">{profile.company}</div>
        <div className="contact-info">
          {profile.telephone && (
            <div className="phone">
              <FontAwesomeIcon icon={faPhone} />
              <a href={`tel:${profile.telephone}`} className="contact-link">{profile.telephone}</a>
            </div>
          )}
          {profile.mobile && (
            <div className="mobile">
              <FontAwesomeIcon icon={faMobileAlt} />
              <a href={`tel:${profile.mobile}`} className="contact-link">{profile.mobile}</a>
            </div>
          )}
          {profile.email && (
            <div className="email">
              <FontAwesomeIcon icon={faEnvelope} />
              <a href={`mailto:${profile.email}`} className="contact-link">{profile.email}</a>
            </div>
          )}
        </div>
      </div>

      <div className="profile-actions">
        {profileActions()}
      </div>

    </div>
    <div className="profile-footer">
      <span>Powered by </span>
      <a href="https://www.keepme.gr" className="footer-link" target="_blank" rel="noopener noreferrer">
        keepme.gr
      </a>
    </div>
    </div>
  );
}

export default UserProfile;
